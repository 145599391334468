import il8n from '@/assets/language/index.js';
const { t } = il8n.global;


// var validateParam = (rule, value, callback) => {
//     Api_.getParmsObj(value).then(response => {
//     if (window.boxType === 'edit') callback()
//     const result = response.data
//     if (result !== null) {
//       callback( t("adminParms.rules1") )
//     } else {
//       callback()
//     }
//   })
// }

export const tableOption1 = {
  border: true,
  stripe: true,
  menuAlign: 'center',
  searchMenuSpan: 6,
  height: 'auto',
  refreshBtn: false,
  columnBtn: false,
  addBtn: false,
  editBtn: false,
  delBtn: false,
  menu:false,
  align: 'center',
  column: [
    {
      label: t("adminParms.name2"),
      prop: 'publicName',
      placeholder: t("inputTit.shuru")
    },
    {
      label: t("adminParms.name3"),
      prop: 'publicKey',
      placeholder: t("inputTit.shuru")
    },
    {
      label: t("adminParms.name4"),
      overHidden: true,
      prop: 'publicValue',
      placeholder: t("inputTit.shuru")
    },
    {
      label: t("adminParms.name5"),
      prop: 'validateCode',
      placeholder: t("inputTit.shuru")
    },
    {
      label: t("adminParms.name6"),
      prop: 'systemFlag',
    },
    {
      label: t("adminParms.name7"),
      prop: 'status',
      width: 80,
    },
    {
      label: t("adminParms.name8"),
      prop: 'publicType',
      width: 100,
    },{
        label: t("button.operation"),
        prop: 'caozuo',
        placeholder: t("inputTit.shuru"),
        fixed:'right',
      },
    // 省略 ...
  ]
}
