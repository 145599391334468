import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
import { parmsArray } from '@/config/data'

export const object = [{
    type: 'input',
    name: t("adminParms.name2"),
    prop: 'publicName',
},{
    type: 'select',
    name: t("adminParms.name6"),
    prop: 'systemFlag',
    isParms: parmsArray[1]
}]