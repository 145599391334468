<template>
  <div class="execution">
        <fromSearch :object_='object_' @searchFun='getList'/>
        <avue-crud ref="crud" :page="page" :data="page.list"
            :table-loading="listLoading"
            :option="tableOption"
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #menu-left>
                <el-button v-if="admin_syspublicparam_del" type="primary" 
                    @click="openView(0)" >{{$t("button.add")}} </el-button>
                <!-- <el-button v-if="admin_syspublicparam_del" type="primary"
                      @click="handleRefreshCache"  >{{ $t("button.refresh") }}  </el-button> -->
            </template>
            <template #systemFlag="scope">
                {{getDataAboutText(1,scope.row.systemFlag)}}
            </template>
            <template #publicType="scope">
                {{getDataAboutText(3,scope.row.publicType)}}
            </template>
            <template #status="scope">
                {{getDataAboutText(4,scope.row.status)}}
            </template>
            <template #caozuo="scope" >
                <el-button v-if="admin_syspublicparam_del" text
                    type="primary" size="small" @click="openView(1,scope.row)">{{$t("button.update")}}
                </el-button>
                    <el-button v-if="admin_syspublicparam_del" text
                    type="primary" size="small" @click="rowDel(scope.row)">{{$t("button.del")}}
                </el-button>
            </template>
        </avue-crud>
        <el-dialog v-model="dialogFormVisible" :title='isStyleText[isStyle]' width="600">
            <bindView ref='bindView_' @successFun='successFun'/>
            <template #footer>
                <el-button   @click="dialogFormVisible = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"  @click="completeFun_">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>
  </div>
</template>

<script setup>
import { ref,getCurrentInstance,nextTick } from 'vue'
import { tableOption1 } from "@/const/crud/parms";
import bindView from './bind.vue'
import { object } from '@/const/from/admin/parms'
import fromSearch from '@/components/fromSearch.vue'
import { getDataAboutText,getDataStyle } from '@/utils/dataBind/parmsView'
import { parmsArray } from '@/config/data'

import mixins from '@/views/mixins/page'
const { $t } = getCurrentInstance().proxy;
let pageObj = mixins(['parmsListAll',['delParmsObjAll','publicKey']],{descs: "create_time"})
const {page,dialogFormVisible,listLoading,sizeChange,currentChange,getList,rowDel,successFun} = pageObj
const isStyle = ref(0)
const isStyleText = [$t("button.add"),$t("button.update")]
const object_ = ref(object);

const tableOption = ref(null);tableOption.value = tableOption1
 //用户权限
const admin_syspublicparam_del = ref(true)
const bindView_ = ref(null)


// const handleRefreshCache = ()=>{
//     Api_.refreshParmsObj().then(() => {
//         ElMessage.success($t("alert.succSync"))
//     })
// }
getDataStyle(parmsArray[3],()=>{})
getDataStyle(parmsArray[4],()=>{})
const completeFun_ = () => {
    bindView_.value.completeFun()
}
const openView = (num,parm)=>{
    isStyle.value = num
    dialogFormVisible.value = true
    nextTick(()=>{
        bindView_.value.init_(num,parm)
    })
}
getList(1)
</script>
